


























































import { Component, Prop, Vue } from 'vue-property-decorator'
import Modal from '@/app/ui/components/Modal/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import FileUpload from '@/app/ui/components/FileUpload/index.vue'
import CloseLine from '@/app/ui/assets/close_icon_line.vue'
import { EnumStatusUpload } from '@/app/infrastructures/misc/Constants/upload'
import ModalAction from '@/app/ui/components/Modal/ModalAction.vue'

@Component({
  components: {
    ModalAction,
    Modal,
    Button,
    CloseLine,
    FileUpload,
  },
})
export default class ModalUploadBulky extends Vue {
  @Prop({ type: String, default: EnumStatusUpload.START }) statusUpload!: EnumStatusUpload
  @Prop({ type: String, default: '' }) fileName!: string
  @Prop({ type: Boolean, default: false }) isForceStart!: boolean
  @Prop({ type: Boolean, default: false }) isInvalidFile!: boolean
  @Prop({ type: Boolean, default: false }) isProcessing!: boolean
  @Prop({ type: Boolean, default: false }) showModalUploadFile!: boolean
  @Prop({ type: Boolean, default: false }) isDisplayModalBlock!: boolean
  @Prop({ type: Boolean, default: false }) showFailedModal!: boolean
  @Prop({ type: Boolean, default: false }) showFailedModalCloseBtn!: boolean
  @Prop({ type: String, default: '' }) failedModalIcon!: string
  @Prop({ type: String, default: '' }) failedModalTitle!: string
  @Prop({ type: String, default: '' }) failedModalDesc!: string
}
