









































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import Button from '@/app/ui/components/Button/index.vue'
import Note from '@/app/ui/components/Note/Note.vue'
import BackIcon from '@/app/ui/assets/icon_back.vue'
import controller, {
  AdjustmentParcelPoinParamsInterface,
} from '@/app/ui/controllers/AdjustmentParcelPoinController'
import { UploadBulkyParcelPoin } from '@/domain/entities/AdjustmentParcelPoin'
import { Utils } from '@/app/infrastructures/misc/Utils'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import ModalUploadBulky from '@/app/ui/views/Adjustment/components/ModalUploadBulky/RequestPoin.vue'
import { EnumStatusUpload } from '@/app/infrastructures/misc/Constants/upload'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import {
  EnumFilterStatusPreviewUploadBulky,
  EventBusConstants,
  PREVIEW_UPLOAD_BULKY_DEFAULT_PAGINATION,
} from '@/app/infrastructures/misc'
import DataTableV2 from '@/app/ui/components/DataTableV2/index.vue'
import EmptyState from '@/app/ui/components/EmptyState/EmptyState.vue'
import { DataObject, HeaderObject } from '@/app/ui/components/DataTableV2/type'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import ModalAction from '@/app/ui/components/Modal/ModalAction.vue'
import Modal from '@/app/ui/components/Modal/index.vue'
import SuccessIcon from '@/app/ui/assets/success_icon_modal.vue'
import { Dictionary } from 'vue-router/types/router'

interface NoteInterface {
  type: 'normal' | 'failed' | 'success'
  text: string
  isVisible: boolean
}

interface ReportInterface {
  fileName: string
  totalRowInvalid: string | number
  totalRowValid: string | number
  totalValidAmountNegative: string
  totalValidAmountPositive: string
  totalInvalidAmountNegative: string
  totalInvalidAmountPositive: string
}

interface statusOptionInterface {
  label: string
  value: EnumFilterStatusPreviewUploadBulky
}

interface parametersInterface {
  page: number
  perPage: number
  status: statusOptionInterface
}

interface perPageOptionInterface {
  label: string
  value: number
}

interface queriesInterface {
  page: string
  perPage: string
  status: string
}

interface modalInfoInterface {
  visible: boolean
  title: string
  dataFailed: Array<string | number>
  textButton: string
}

@Component({
  components: {
    Button,
    BackIcon,
    Note,
    LoadingOverlay,
    ModalUploadBulky,
    DropdownSelect,
    DataTableV2,
    EmptyState,
    PaginationNav,
    ModalAction,
    Modal,
    SuccessIcon,
  },
})
export default class PreviewUploadBulk extends Vue {
  note: NoteInterface = {
    type: 'normal',
    text: '',
    isVisible: false,
  }
  report: ReportInterface = {
    fileName: '-',
    totalRowInvalid: '-',
    totalRowValid: '-',
    totalValidAmountNegative: '-',
    totalValidAmountPositive: '-',
    totalInvalidAmountNegative: '-',
    totalInvalidAmountPositive: '-',
  }
  showUploadModal = false
  statusOptions: Array<statusOptionInterface> = [
    {
      label: 'Semua',
      value: EnumFilterStatusPreviewUploadBulky.ALL,
    },
    {
      label: 'Valid',
      value: EnumFilterStatusPreviewUploadBulky.VALID,
    },
    {
      label: 'Invalid',
      value: EnumFilterStatusPreviewUploadBulky.INVALID,
    },
  ]
  parameters: parametersInterface = {
    page: 1,
    perPage: 10,
    status: this.statusOptions[0],
  }
  controller = controller
  headers: Array<HeaderObject> = [
    {
      title: 'Baris',
      customStyle: { maxWidth: '76px', minWidth: '76px', textAlign: 'center' },
    },
    {
      title: 'Customer ID',
      customStyle: { maxWidth: '120px', minWidth: '120px' },
    },
    {
      title: 'Jumlah',
      customStyle: { maxWidth: '140px', minWidth: '140px' },
    },
    {
      title: 'Catatan',
      customStyle: { maxWidth: '500px', minWidth: '500px' },
    },
    {
      title: 'Status',
      customStyle: { maxWidth: '200px', minWidth: '200px' },
    },
  ]
  perPageOptions: Array<perPageOptionInterface> = [
    {
      label: '50',
      value: 50,
    },
  ]
  modalInfo: modalInfoInterface = {
    visible: false,
    title: '',
    dataFailed: [0, ''],
    textButton: '',
  }
  isModalSuccessVisible = false

  mounted(): void {
    this.onSetDisplayBreadcrumb(false)
  }

  created(): void {
    const queries = <queriesInterface>(<unknown>this.$route.query)
    this.parameters = {
      page: Utils.alwaysNumber(queries.page) || 1,
      perPage: PREVIEW_UPLOAD_BULKY_DEFAULT_PAGINATION,
      status:
        this.statusOptions.find(item => item.value === queries.status) ||
        this.statusOptions[0],
    }
    this.fetchPreviewUploadBulky()
    controller.setStatusUpload(EnumStatusUpload.START)
  }

  private handleBack(): void {
    this.$router
      .push({ name: 'AdjustmentParcelPoinRequestPoinPage' })
      .catch(() => false)
  }

  private actionModalUpload(): void {
    this.showUploadModal = true
  }

  get isDisabledButtonSendData(): boolean {
    return (
      Number(this.report.totalRowInvalid) !== 0 ||
      Number(this.report.totalInvalidAmountNegative) !== 0 ||
      Number(this.report.totalInvalidAmountPositive) !== 0 ||
      Number(this.report.totalRowValid) === 0 ||
      (Number(this.report.totalValidAmountNegative) === 0 &&
        Number(this.report.totalValidAmountPositive) === 0)
    )
  }

  private handleSendData(): void {
    if (!this.isDisabledButtonSendData) {
      this.controller.processBulkyRequestParcelPoin()
    }
  }

  @Watch('controller.responseUploadBulky')
  onResponseUploadBulky(val: UploadBulkyParcelPoin | null): void {
    if (val?.result) {
      const text =
        val.result.totalRowInvalid === 0
          ? 'Semua data telah sesuai. Silahkan tekan tombol Kirim Data untuk mengirim data'
          : `${val.result.totalRowInvalid ||
              0} baris data tidak valid. Silahkan perbaiki data dan upload ulang `
      this.note = {
        isVisible: !(
          Number.isNaN(val.result.totalRowInvalid) || !val?.result.fileName
        ),
        text,
        type:
          val.result.totalRowInvalid === 0 &&
          val.result.totalInvalidAmountNegative === 0 &&
          val.result.totalInvalidAmountPositive === 0
            ? 'success'
            : 'failed',
      }
      this.report = {
        fileName: val.result.fileName || '-',
        totalRowInvalid: Number.isNaN(val.result.totalRowInvalid)
          ? '-'
          : Utils.currencyDigit(val.result.totalRowInvalid),
        totalRowValid: Number.isNaN(val.result.totalRowValid)
          ? '-'
          : Utils.currencyDigit(val.result.totalRowValid),
        totalValidAmountNegative:
          val.result.totalValidAmountNegative === 0 ||
          Number.isNaN(val.result.totalValidAmountNegative)
            ? '0'
            : `${Utils.currencyDigit(val.result.totalValidAmountNegative)}`,
        totalValidAmountPositive:
          val.result.totalValidAmountPositive === 0 ||
          Number.isNaN(val.result.totalValidAmountPositive)
            ? '0'
            : `+${Utils.currencyDigit(
                Math.abs(val.result.totalValidAmountPositive)
              )}`,
        totalInvalidAmountNegative:
          val.result.totalInvalidAmountNegative === 0 ||
          Number.isNaN(val.result.totalInvalidAmountNegative)
            ? '0'
            : `${Utils.currencyDigit(val.result.totalInvalidAmountNegative)}`,
        totalInvalidAmountPositive:
          val.result.totalInvalidAmountPositive === 0 ||
          Number.isNaN(val.result.totalInvalidAmountPositive)
            ? '0'
            : `+${Utils.currencyDigit(
                Math.abs(val.result.totalInvalidAmountPositive)
              )}`,
      }
    }
  }

  @Watch('controller.statusSuccessProcessBulkyRequestParcelPoin')
  onStatusSuccessProcessBulkyRequestParcelPoinChanged(status: string): void {
    switch (status) {
      case EventBusConstants.PROCESS_UPLOAD_SALDO_BULKY_SUCCESS:
        this.isModalSuccessVisible = true
        break
      case EventBusConstants.PROCESS_UPLOAD_SALDO_BULKY_RETRY:
        this.modalInfo = {
          visible: true,
          title: 'Gagal Proses beberapa Data',
          dataFailed: [controller.dataRetry, 'silahkan kirim ulang'],
          textButton: 'Coba Lagi',
        }
        break
      case EventBusConstants.PROCESS_UPLOAD_SALDO_BULKY_NOT_RETRY:
        this.modalInfo = {
          visible: true,
          title: 'Beberapa Data Gagal diProses',
          dataFailed: [
            controller.dataRetry,
            'silahkan lihat detail dan upload kembali',
          ],
          textButton: 'Lihat Detail',
        }
        break
    }
    this.controller.setStatusSuccessProcessBulkyRequestParcelPoin('')
  }

  private closeModalSuccess(): void {
    this.controller.setStatusSuccessProcessBulkyRequestParcelPoin('')
    this.isModalSuccessVisible = false
    this.handleBack()
  }

  @Watch('controller.statusUploadBulky')
  onStatusUploadBulkyCompleted(status: string | null): void {
    if (
      status === EnumStatusUpload.COMPLETE &&
      this.$route.name ===
        'PreviewUploadBulkAdjustmentParcelPoinRequestPoinPage'
    ) {
      controller.setStatusUpload(EnumStatusUpload.START)
      this.showUploadModal = false
      this.controller.getPreviewUploadBulky(this.params)
    }
  }

  private onSetDisplayBreadcrumb(isDisplayed: boolean): void {
    const breadcrumb = document.getElementById('breadcrumb')
    if (breadcrumb) {
      if (isDisplayed) {
        breadcrumb.style.display = 'flex'
      } else {
        breadcrumb.style.display = 'none'
      }
    }
  }

  get params(): AdjustmentParcelPoinParamsInterface {
    return {
      ...this.parameters,
      status: this.parameters.status.value,
    }
  }

  @Watch('params')
  onParamsChanged(
    val: Dictionary<string | (string | null)[] | null | undefined> | undefined
  ): void {
    this.$router
      .replace({
        query: { ...val },
      })
      .catch(() => false)
  }

  private fetchPreviewUploadBulky(resetPage = false): void {
    if (resetPage) {
      this.parameters.page = 1
    }

    this.controller.getPreviewUploadBulky(this.params)
  }

  private resetModalInfo(): void {
    this.modalInfo = {
      visible: false,
      title: '',
      dataFailed: [0, ''],
      textButton: '',
    }
  }

  private handleAction(): void {
    if (this.controller.isLoading) return
    switch (this.modalInfo.textButton) {
      case 'Coba Lagi':
        this.handleSendData()
        break
      case 'Lihat Detail':
        this.resetModalInfo()
        this.$router
          .push({
            name: 'AdjustmentParcelPoinRequestPoinPage',
            query: { tab: 'upload-bulky' },
          })
          .catch(() => false)
        break
    }
  }

  get previewUploadBulk(): Array<DataObject[]> {
    if (this.controller.responseUploadBulky?.data === undefined) return []
    if (
      this.controller?.responseUploadBulky &&
      this.controller?.responseUploadBulky?.data.length > 0
    ) {
      return this.controller.responseUploadBulky.data.map(item => {
        return [
          {
            value: item.rowNumber,
            customStyle: {
              maxWidth: '76px',
              minWidth: '76px',
              textAlign: 'center',
            },
          },
          {
            value: item.customerID,
            customStyle: { maxWidth: '120px', minWidth: '120px' },
          },
          {
            value:
              item.totalSaldo === 0
                ? '0'
                : `${item.totalSaldo > 0 ? '+' : ''}${Utils.currencyDigit(
                    item.totalSaldo
                  )}`,
            customStyle: { maxWidth: '140px', minWidth: '140px' },
          },
          {
            value: item.note,
            customStyle: { maxWidth: '500px', minWidth: '500px' },
          },
          {
            value: item.isValid ? 'VALID' : item.reasonInvalid,
            customStyle: { maxWidth: '200px', minWidth: '200px' },
          },
        ]
      })
    }
    return []
  }

  get totalItemPagination(): number {
    return this.controller.responseUploadBulky?.pagination.totalItem || 0
  }

  private closeUploadModal(refetch?: boolean): void {
    this.showUploadModal = false
    if (refetch === true) {
      this.fetchPreviewUploadBulky(true)
    }
  }

  beforeDestroy(): void {
    this.onSetDisplayBreadcrumb(true)
    this.controller.setStatusSuccessProcessBulkyRequestParcelPoin('')
    this.controller.setDatRetry(NaN)
  }
}
